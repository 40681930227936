import * as Sentry from "@sentry/node";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import getConfig from "next/config";
import Head from "next/head";
import React, { useEffect } from "react";
import theme from "../theme";
import { AppProps } from "next/app";
import { config } from "@fortawesome/fontawesome-svg-core";
import { RewriteFrames } from "@sentry/integrations";
import {
  createStyles,
  makeStyles,
  ThemeProvider,
} from "@material-ui/core/styles";
import "@fortawesome/fontawesome-svg-core/styles.css";

config.autoAddCss = false;

if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
  const config = getConfig();
  const distDir = `${config.serverRuntimeConfig.rootDir}/.next`;

  Sentry.init({
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
    release: process.env.NEXT_PUBLIC_SENTRY_RELEASE,
    environment: process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT,
    integrations: [
      new RewriteFrames({
        iteratee: (frame) => {
          frame.filename = frame.filename?.replace(distDir, "app:///_next");
          return frame;
        },
      }),
    ],
    tracesSampleRate: 1.0,
  });
}

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      root: {
        display: "flex",
        flexDirection: "column",
        height: "100%",
        backgroundImage: "url(/assets/header.svg)",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "right top",
        // [theme.breakpoints.up("md")]: {
        //   paddingBottom: 120,
        //   backgroundImage: "url(/assets/header.svg), url(/assets/footer.svg)",
        //   backgroundPosition: "right top, left bottom",
        // },
      },
      nav: {
        padding: 24,
      },
      container: {
        flex: 1,
        padding: 24,
      },
    }),
  { name: "App" }
);

/**
 * The main NextJS Application.
 *
 * @param props
 */
const App: React.FC<AppProps> = ({ Component, pageProps }) => {
  const classes = useStyles();

  useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector("#jss-server-side");

    jssStyles?.parentElement?.removeChild(jssStyles);
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />

      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <title>Urbvan: Sugiere</title>
      </Head>

      <div className={classes.root}>
        <nav className={classes.nav}>
          <img
            src="/assets/logo.svg"
            alt="Urbvan Transit"
            width="113"
            height="34"
          />
        </nav>

        <Component {...pageProps} />
      </div>
    </ThemeProvider>
  );
};

export default App;
