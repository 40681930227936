import { createMuiTheme } from "@material-ui/core/styles";

/**
 * This is the Material UI theme for Sugiere.
 */
const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#467ec0",
    },
    error: {
      main: "#f1404b",
    },
    success: {
      main: "#1db117",
    },
    text: {
      primary: "#252c41",
      secondary: "#252c41a1",
    },
    background: {
      default: "white",
    },
  },
  typography: {
    h5: {
      fontSize: 20,
      fontWeight: 300,
    },
    h6: {
      fontSize: 20,
      fontWeight: 500,
      textTransform: "uppercase",
    },
    body1: {
      fontSize: 15,
      fontWeight: 300,
    },
    body2: {
      fontSize: 18,
      fontWeight: 300,
    },
    button: {
      fontSize: 17,
      fontWeight: 300,
      textTransform: "none",
    },
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
  shape: {
    borderRadius: 5,
  },
  props: {
    MuiButton: {
      disableElevation: true,
    },
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        html: {
          WebkitFontSmoothing: "antialiased",
          minHeight: "100%",
        },
        body: {
          height: "100%",
        },
        "#__next": {
          height: "100%",
        },
        "#root": {
          height: "100%",
        },
      },
    },
  },
});

export default theme;
